import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ArrowForward from "@material-ui/icons/ArrowForward"

import SEO from "../components/SEO"
import Navigation from "../components/Navigation"
import BackgroundVideo from "../components/Video"
import Footer from "../components/Footer"


const IndexPage = () => {
    const Banner = () => {
        return (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography variant="h1" align="center" gutterBottom>
                    Bring your ideas to life
                </Typography>
                <Button 
                    variant="contained" 
                    color="primary" 
                    href="/contact"
                    endIcon={<ArrowForward/>}
                >
                    Get Started
                </Button>
            </Box>
        )
    }

    return (
        <>
            <SEO title="Swizzo Studios"/>
            <Navigation />
            <BackgroundVideo>
                <Banner />
            </BackgroundVideo>
            <Footer />
        </>
    )
}

export default IndexPage
