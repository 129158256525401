import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"


const Footer = () => {
    return (
        <Box pt={4} pb={4} mt={16}>
            <Typography variant="body1" align="center" color="textSecondary" gutterBottom>
                All Rights Reserved
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary">
                Swizzo Studios &#169; 2020
            </Typography>
        </Box>
    )
}

export default Footer
