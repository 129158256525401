import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import backgroundVideo from "../assets/background.mp4"


const useStyles = makeStyles(theme => ({
    root: {
        height: "100%",
        width: "100%",
        overflow: "hidden",
    },
    video: {
        objectFit: "cover",
        maxHeight: "100vh",
        opacity: 0.4,
        zIndex: -1,
    },
    children: {
        position: "absolute",
        top: 0,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
}))


const BackgroundVideoVideo = (props) => {
    const classes = useStyles()

    return (
        <div className={ classes.root }>
            <video autoPlay muted loop controlsList="nodownload" className={ classes.video }>
                <source src={backgroundVideo} title="background" />
            </video>
            <div className={ classes.children }>
                { props.children }
            </div>
        </div>
    )
}

export default BackgroundVideoVideo
